import { defineStore } from "pinia";

export const useModalImageSlider = defineStore("modalImageSlider", () => {
  const modalIsOpen = ref(false);
  const initialSlide = ref(0);
  const sliderImages = ref([]);
  const loading = ref(true);
  const loadedImages = ref(0);
  let loadingTimeout = null;
  const loadingTimeoutSeconds = 3;

  // Set sliderImages to array of objects:
  // [
  //   {
  //     imageFullSrc: "/img/example.png",
  //     alt: "alt text",
  //   },
  //   ...
  // ]

  const closeModal = () => {
    modalIsOpen.value = false;
    loading.value = false;
    loadedImages.value = 0;

    if (loadingTimeout) {
      clearTimeout(loadingTimeout);
      loadingTimeout = null;
    }
  };

  const openModal = (index) => {
    initialSlide.value = index;
    modalIsOpen.value = true;
    loading.value = true;
    loadedImages.value = 0;

    loadingTimeout = setTimeout(() => {
      loading.value = false;
      console.warn(
        "Images taking more than",
        loadingTimeoutSeconds,
        "seconds to load. Cancelling loading state.",
      );
    }, loadingTimeoutSeconds * 1000);
  };

  const handleImageLoad = () => {
    loadedImages.value++;

    if (loadedImages.value >= sliderImages.value.length) {
      loading.value = false;

      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
        loadingTimeout = null;
      }
    }
  };

  return {
    closeModal,
    handleImageLoad,
    initialSlide,
    loadedImages,
    loading,
    modalIsOpen,
    openModal,
    sliderImages,
  };
});
